// import { useEffect } from "react";

// const useClickOutside = (ref: React.RefObject<any>, callback: () => void) => {
    

//   useEffect(() => {
//     function handleClick(event: { target: any; }) {
//         if (ref.current && !ref.current.contains(event.target)) {
//           callback();
//         }
//       };
//     //document.onclick = handleClick
    
//     return () => {
//       //document.removeEventListener("click", handleClick);
//     };
//   });
// };

// export default useClickOutside;
import { useEffect, RefObject } from 'react';

type Event = MouseEvent | TouchEvent;

const useClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }

      handler(event); // Call the handler only if the click is outside of the element passed.
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]); // Reload only if ref or handler changes
};
export default useClickOutside;
